<template>
  <div>
    <br />
    <div class="row justify-content-end">
     

      <div class="col-auto">
        <button @click="referesh()" class="btn btn-secondary">
          <i class="bi bi-plus-circle"></i>
          Actualiser
        </button>
      </div>
    </div>
    <br />
    <div class="row">
      <div class="col-xl-3 col-12 my-3">
        <label for=""> Nom </label>
        <div>
          {{ customer.lastName }}
        </div>
      </div>

      <div class="col-xl-3 col-12 my-3">
        <label for=""> Prénom </label>
        <div>
          {{ customer.firstName }}
        </div>
      </div>

      <div class="col-xl-3 col-12 my-3">
        <label for=""> Adresse </label>
        <div>
          {{ customer.address }}
        </div>
      </div>

      <div class="col-xl-3 col-12 my-3">
        <label for=""> Raison Social </label>
        <div>
          {{ customer.society }}
        </div>
      </div>

      <div class="col-xl-3 col-12 my-3">
        <label for=""> Ville </label>
        <div>
          {{ customer.city }}
        </div>
      </div>

      <div class="col-xl-3 col-12 my-3">
        <label for=""> Code Postal </label>
        <div>
          {{ customer.codePostal }}
        </div>
      </div>

      <div
        v-show="($userRole = !'Agent d\'atelier automobile ')"
        class="col-xl-3 col-12 my-3"
      >
        <label for="">CIN</label>
        <div>
          {{ customer.cin }}
        </div>
      </div>

      <div class="col-xl-3 col-12 my-3">
        <label for="">Téléphone</label>
        <div>
          {{ customer.phone }}
        </div>
      </div>

      <div class="col-xl-3 col-12 my-3">
        <label for="">E-mail</label>
        <div>
          {{ customer.email }}
        </div>
      </div>

      <div
        
        class="col-xl-3 col-12 my-3"
      >
        <label for=""> ICE </label>
        <div>
          {{ customer.ice }}
        </div>
      </div>

      

      <div
        
        class="col-xl-3 col-12 my-3"
      >
        <label for="">Montant Impayé Maximum</label>
        <div>
          {{ customer.maxUnpaid }}
        </div>
      </div>

      <div class="col-xl-3 col-12 my-3">
        <label for="">Latitude</label>
        <div>
          {{ customer.latitude }}
        </div>
      </div>

      <div class="col-xl-3 col-12 my-3">
        <label for="">Longitude</label>
        <div>
          {{ customer.longitude }}
        </div>
      </div>

      <div class="col-xl-3 col-12 my-3">
        <label for=""> </label>
        <div>
          <p v-if="googleMapsUrl">
            <a :href="googleMapsUrl" target="_blank" class=""> Google Maps</a>
          </p>
        </div>
      </div>

      <div
        class="col-xl-3 col-12 my-3"
      >
        <label for=""> Employee </label>
        <div v-if="customer.employee">
          {{ customer.employee.fullName }}
        </div>
      </div>

    </div>

    <div class="row mt-3">
      <div class="col">
        <label for=""> Remarque </label>
        <div>
          {{ customer.remark }}
        </div>
      </div>
    </div>

    <div class="row justify-content-end">
      <div class="col-auto">
        <button class="btn btn-secondary">
          Ventes
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      // customer: {},
    };
  },
  computed: {
    ...mapGetters("customer", {
      customer_types: "getCustomerTypes",
      customer: "getCustomer",
    }),
    googleMapsUrl() {
      const { latitude, longitude } = this.customer;
      if (latitude && longitude) {
        return `https://www.google.com/maps?q=${latitude},${longitude}`;
      }
      return null;
    },
  },
  methods: {
    async save(data) {
      await this.$store.dispatch("customer/update", data);
    },
    async referesh() {
      await this.$store.dispatch("customer/getCustomerTypes");
      await this.$store.dispatch("customer/show", this.$route.params.reference);
    },
  },
  beforeMount() {
    this.$store.dispatch("customer/getCustomerTypes");
    this.$store.dispatch("customer/show", this.$route.params.reference);
  },
};
</script>
